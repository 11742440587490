import { gql } from "@apollo/client";

const GET_CATEGORIES = gql`query GetCategories($categorySort: [String], $techniqueSort: [String]) {
  category(sort: $categorySort) {
    id
    name
    techniques(sort: $techniqueSort) {
      id
      name
    }
  }
}`;

export default GET_CATEGORIES;
import './header.css';
import * as React from "react";
import HeaderButtonGroup from "./HeaderButtonGroup";
import Grid from '@mui/material/Grid';
import NavTabs from './components/HeaderLinkTab';
import MobileDiv from './components/MobileDiv';
import PcDiv from './components/PcDiv';
import DoTogetherModal from './DoTogetherModal.js';
import Button from '@mui/material/Button';

export default function Header() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <nav class="navbar navbar-light navbar-expand-md bg-light fixed-top">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvasLg" aria-controls="navbarOffcanvasLg">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-start" tabindex="-1" id="navbarOffcanvasLg" aria-labelledby="navbarOffcanvasLgLabel">
          <PcDiv>
            <div class="navbar-nav">
              <Grid container columns={18}>
                <Grid item xs={2}/>
                <Grid item xs={8}>
                  <NavTabs></NavTabs>  
                </Grid>
                <Grid item xs={6}>
                  <div className='navbar-do-together-button'>
                    <Button variant="contained" color='secondary' onClick={handleOpen}>Написать мне</Button>
                    <DoTogetherModal open={open} handleClose={handleClose}/>  
                  </div>
                </Grid>
                <Grid item xs={2}/>
              </Grid>
            </div>
          </PcDiv>
          <MobileDiv className="mobile-navbar">
            <div class="navbar-nav">
              <HeaderButtonGroup></HeaderButtonGroup>
            </div>
          </MobileDiv>
          
        </div>
      </div>
    </nav>
  );
}

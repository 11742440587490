import { gql } from "@apollo/client";

export const GET_ALL_TECHNIQUES = gql`query GetAllTechniques{
	technique {
    description
    id
    name
    thumbnail {
      id
    }
  }
}`;

export const GET_TECHNIQUE_BY_ID = gql`query GetTechniqueById($techniqueId: ID!) {
  technique_by_id(id: $techniqueId) {
    full_description
    name,
    description,
    images {
      id
      directus_files_id {
        id
      }
    }
  }
}`;

export default GET_ALL_TECHNIQUES;



import "./technique-info.css";
import "./App.css";
import { GET_TECHNIQUE_BY_ID } from "./graphql/Techniques";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import * as React from 'react';
import DoTogetherModal from './DoTogetherModal.js'
import { BackendUrl } from './Constants';
import ModalImage from "react-modal-image-responsive";
import MobileDiv from './components/MobileDiv';
import PcDiv from "./components/PcDiv";
import Button from '@mui/material/Button';

function Carousel(props){
  let active = true;
  let carouselItems = props.imageUrls.map(url => {
    let classValue = active ? "carousel-item active" : "carousel-item";
    active = false;
    return(
      <div class={classValue} data-bs-interval="3000">
        <PcDiv>
          <ModalImage
            className="w-100 h-100"
            small={url}
            large={url}
            alt="..."/>
        </PcDiv>
        <MobileDiv>
          <img src={url} class="img-fluid" alt="..."/>
        </MobileDiv>
      </div>);
    })

  return(<div id="techniqueCarousel" class="carousel slide technique-carousel" data-bs-ride="carousel">
  <div class="carousel-inner">
    {carouselItems}
  </div>
</div>);
}

export default function TechniqueInfo(props) {
  let { techniqueId } = useParams();
  const { loading, error, data } = useQuery(GET_TECHNIQUE_BY_ID, {
    variables: { techniqueId },
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (loading) return null;
  if (error) return `Error! ${error}`;

  let technique = data.technique_by_id;
  let imageIds = technique.images.map((image) => image.directus_files_id.id);
  let imageUrls = imageIds.map(
    (id) => BackendUrl + "assets/" + id
  );
  let fullDescription = technique.full_description;
  let shortDescription = technique.description;
  let carousel = <Typography class="technique-no-images-typography" variant="h2">Нет доступных картинок</Typography>;
  if (imageUrls && imageUrls.length >= 1){
    carousel = <Carousel imageUrls={imageUrls}></Carousel>
  }
   
  return (
    <div className="technique-info-content">
      <Typography variant="h5">
        {technique.name}
      </Typography>
      {carousel}

      <Typography variant="body1" color="text.secondary">
        <div
          className="short-description-text"
          dangerouslySetInnerHTML={{ __html: shortDescription }}>
        </div>
      </Typography>

      <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              Полное описание практики
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              <Typography variant="body2" color="text.secondary">
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: fullDescription }}>
                </div>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '1rem', marginTop: '1rem'}}>
        <Button variant="contained" color='secondary' onClick={handleOpen}>Сделать вместе</Button>
        <DoTogetherModal open={open} handleClose={handleClose}/>
      </div>
    </div>
  );
}

import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './Header'
import ItemTable from './ItemTable';
import Grid from '@mui/material/Grid';
import CategoryMenu from './CategoryMenu';
import { Switch, Route } from "react-router-dom";
import TechniqueInfo from './TechniqueInfo';
import MainPage from './MainPage';
import ContactsPage from './ContactsPage';
import AboutPage from './pages/AboutPage';
import BeforeStartPage from './pages/BeforeStartPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import GET_STYLES from './graphql/Styles';
import { useQuery } from "@apollo/client";
import JSON5 from 'json5';

function App() {
  let themeObj = {};
  const { loading, error, data } = useQuery(GET_STYLES);
  if (!loading && !error){
    try{
      let themeStr = data.style[0].main_style;
      themeObj = JSON5.parse(themeStr);
    }
    catch(ex){ console.log(ex); }
  }

  let theme = createTheme(themeObj);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header/>
        <div class="pc-body d-none d-sm-none d-md-block main-body-container">
          <Grid container columns={18}>
            <Grid item xs={2}/>
            <Grid item xs={4}>
              <div className="d-none d-sm-none d-md-block">
                  <CategoryMenu />
              </div>
            </Grid>
            <Grid item xs={10}>
              <Switch>
                <Route exact path="/techniques">
                  <ItemTable/>
                </Route>
                <Route exact path="/">
                  <MainPage/>
                </Route>
                <Route exact path="/contacts">
                  <ContactsPage/>
                </Route>
                <Route exact path="/about">
                  <AboutPage/>
                </Route>
                <Route exact path="/before-start">
                  <BeforeStartPage/>
                </Route>
                <Route path="/techniques/:techniqueId">
                  <TechniqueInfo></TechniqueInfo>
                </Route>
              </Switch>
            </Grid>
            <Grid item xs={2}/>
          </Grid>
        </div>
        <div class="mobile-body d-block d-sm-block d-md-none">
          <Grid container spacing={2} columns={24}>
              <Grid item xs={1}/>
              <Grid item xs={22}>
                <Switch>
                  <Route exact path="/techniques">
                    <ItemTable/>
                  </Route>
                  <Route exact path="/">
                    <MainPage/>
                  </Route>
                  <Route exact path="/contacts">
                    <ContactsPage/>
                  </Route>
                  <Route exact path="/about">
                    <AboutPage/>
                  </Route>
                  <Route exact path="/before-start">
                    <BeforeStartPage/>
                  </Route>
                  <Route path="/techniques/:techniqueId">
                    <TechniqueInfo></TechniqueInfo>
                  </Route>
                </Switch>
              </Grid>
              <Grid item xs={1}/>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;

import "../App.css";
import * as React from 'react';
import { GET_COMPONENT_TEXT_BY_COMPONENT_NAME } from "../graphql/ComponentTexts";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import DoTogetherModal from '../DoTogetherModal.js';
import Button from '@mui/material/Button';

export default function BeforeStartPage(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const pageName = 'before_start';
  const { loading, error, data } = useQuery(GET_COMPONENT_TEXT_BY_COMPONENT_NAME, {
    variables: {
        "filter": {
          "page": {
            "_eq": pageName
          }
        }
      },
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;

  let firstPageComponent = data.component_texts.find(ct => ct.component_name === 'first_block');
  let secondPageComponent = data.component_texts.find(ct => ct.component_name === 'second_block');
  let thirdPageComponent = data.component_texts.find(ct => ct.component_name === 'third_block');
  let fourthPageComponent = data.component_texts.find(ct => ct.component_name === 'fourth_block');

  return (
    <div className="before-start-page-content">
      <div className="row">
        <div class="pc-before-start-page d-none d-sm-none d-md-block">
          <div className="before-start-page-content">
            <div className="row">
              <div className="col">
                <Typography variant="body2" color="text.secondary">
                  <div
                    className="content before-start-page-text"
                    dangerouslySetInnerHTML={{ __html: firstPageComponent.component_text }}>
                  </div>
                </Typography>
              </div>
              <div className="col">
                <Typography variant="body2" color="text.secondary">
                    <div
                      className="content before-start-page-text"
                      dangerouslySetInnerHTML={{ __html: secondPageComponent.component_text }}>
                    </div>
                  </Typography>
              </div>
            </div>
            <div className="row before-start-page-row">
              <div className="col">
                <Typography variant="body2" color="text.secondary">
                  <div
                    className="content before-start-page-text"
                    dangerouslySetInnerHTML={{ __html: thirdPageComponent.component_text }}>
                  </div>
                </Typography>
              </div>
              <div className="col">
                <Typography variant="body2" color="text.secondary">
                    <div
                      className="content before-start-page-text"
                      dangerouslySetInnerHTML={{ __html: fourthPageComponent.component_text }}>
                    </div>
                  </Typography>
              </div>
            </div>
          </div>
        </div>

        <div class="mobile-before-start-page d-block d-sm-block d-md-none">
          <div className="before-start-page-content">
            <div className="row">
              <Typography variant="body2" color="text.secondary">
                <div
                  className="content before-start-page-text"
                  dangerouslySetInnerHTML={{ __html: firstPageComponent.component_text }}>
                </div>
              </Typography>
            </div>
            <div className="row before-start-page-row">
                <Typography variant="body2" color="text.secondary">
                    <div
                      className="content before-start-page-text"
                      dangerouslySetInnerHTML={{ __html: thirdPageComponent.component_text }}>
                    </div>
                  </Typography>
            </div>
            <div className="row before-start-page-row">
                <Typography variant="body2" color="text.secondary">
                    <div
                      className="content before-start-page-text"
                      dangerouslySetInnerHTML={{ __html: secondPageComponent.component_text }}>
                    </div>
                  </Typography>
            </div>
            <div className="row before-start-page-row">
              <Typography variant="body2" color="text.secondary">
                <div
                  className="content before-start-page-text"
                  dangerouslySetInnerHTML={{ __html: fourthPageComponent.component_text }}>
                </div>
              </Typography>
            </div>
            <div className="row before-start-page-row">
              <div style={{ marginBottom: '1rem', marginTop: '1rem'}}>
                <Button variant="contained" color='secondary' onClick={handleOpen}>Написать мне</Button>
                <DoTogetherModal open={open} handleClose={handleClose}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import './item-table.css';
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ItemCard from './ItemCard'
import GET_ALL_TECHNIQUES from './graphql/Techniques'
import { useQuery } from "@apollo/client";

function Techniques() {
  const { loading, error, data } = useQuery(GET_ALL_TECHNIQUES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  var techniques = data.technique.map((technique) => (
    <ItemCard id={technique.id} name={technique.name} description={technique.description} thumbnail={technique.thumbnail?.id}/>
    ));

  return techniques;
}

export default function ItemTable() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Stack direction="row" spacing={2} className="techniques-table">
        <div className="techniques-table-div">
          <Techniques/>
        </div>
      </Stack>
    </React.Fragment>
  );
}
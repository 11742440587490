/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./App.css";
import { useHistory } from "react-router-dom";
import CategoryMenu from './CategoryMenu';

export default function HeaderButtonGroup() {
  let history = useHistory();

  function handleClick(url) {
    history.push(url);
  }

  return (
<ul class="navbar-nav">
      <li class="nav-item active">
      <Button onClick={() => handleClick("/")} className="HeaderButton">
          <Typography className="HeaderButtonText" css={{ color: "black" }}>
            Главная
          </Typography>
        </Button>
      </li>
      <li class="nav-item">
      <Button
          onClick={() => handleClick("/techniques")}
          className="HeaderButton"
        >
          <Typography className="HeaderButtonText" css={{ color: "black" }}>Практики</Typography>
        </Button>
      </li>
      <li class="nav-item">
      <Button
          onClick={() => handleClick("/before-start")}
          className="HeaderButton"
        >
          <Typography className="HeaderButtonText" css={{ color: "black" }}>Перед началом работы</Typography>
        </Button>
      </li>
      <li class="nav-item">
      <Button
          onClick={() => handleClick("/about")}
          className="HeaderButton"
        >
          <Typography className="HeaderButtonText" css={{ color: "black" }}>Обо мне</Typography>
        </Button>
      </li>
      <div class="d-block d-sm-block d-md-none">
      <li class="nav-item dropdown">
        <CategoryMenu></CategoryMenu>
      </li>
      </div>
    </ul>
  );
}

import * as React from 'react';
import './category-menu.css';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Collapse from '@mui/material/Collapse';
import GET_CATEGORIES from "./graphql/Category.js";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

function Categories() {
    var categorySort = 'order_number';
    var techniqueSort = 'order_number';
    const { loading, error, data } = useQuery(GET_CATEGORIES, {
      variables: { categorySort, techniqueSort },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    var categories = data.category.map((category) => (
        CreateListItemButton(category)
      ));
    return categories;
  }

  function CreateListItemButton(category){
    if (category.techniques !== undefined && category.techniques.length >= 1){
        var techniques = category.techniques.map(technique => <Technique id={technique.id} name={technique.name}/>);
        return (<Category nestedItems={techniques} text={category.name} id={category.id}/>);
    }
  }

  function Technique(props){
    let history = useHistory();

    function handleClick() {
      history.push("/techniques/" + props.id);
    }

    return (<ListItemButton sx={{ pl: 4 }} onClick={handleClick}>
              <ListItemIcon>
                <NavigateNextRoundedIcon/>
              </ListItemIcon>
              <ListItemText primary={props.name} id={props.id} />
            </ListItemButton>)
  }

  function Category(props){
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
      setOpen(!open);
    };

    return (
        <div>
          <ListItemButton onClick={handleClick}>
            <ListItemText primary={props.text} id={props.id} />
            {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {props.nestedItems}
            </List>
          </Collapse>
        </div>
      );
  }

export default function CategoryMenu() {

  return (
    <List className="CategoryMenu"
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Разделы
        </ListSubheader>
      }
    >
      <Categories></Categories>
    </List>
  );
}

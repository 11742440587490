import { gql } from "@apollo/client";

const GET_CONTACT = gql`query GetContact {
    contact {
      phone
      skype
      email
      telegram_name
      vk_nickname
      whatsapp_phone
      facebook_url
    }
  }`;

export default GET_CONTACT;





  
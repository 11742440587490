import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import {css} from '@emotion/react';
import GET_CONTACT from "./graphql/Contact";
import { useQuery } from "@apollo/client";

const containerStyle = {
  width: '100%',
  height: '400px'
};

export default  function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyB8l4WAMpQ3YxQwKe9CptUSmNLDg73FC3A"
  })

  const center = {
    lat: 53.208128,
    lng: 44.995526
  };

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    map.setCenter(
        new window.google.maps.LatLng(center),
    );
    map.setZoom(16);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const { loading, error, data } = useQuery(GET_CONTACT);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {email, phone, skype} = data.contact;

  return isLoaded ? (
          <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={center}></Marker>
      </GoogleMap>    
  ) : <></>
}

/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import "./item-table.css";
import { useHistory } from "react-router-dom";
import { BackendUrl } from './Constants';

export default function ItemCard(props) {
  let history = useHistory();

  function handleClick() {
    history.push("/techniques/" + props.id);
  }

  let thumbnail = BackendUrl + "assets/" + props.thumbnail + "?quality=90&width=300&height=140";
  return (
    <div className="techniques-card" >
      <Card id={props.id} onClick={handleClick} style={{height: "300px"}}>
        <CardActionArea>
          <CardMedia css={css`min-width:300px;`}
            component="img"
            height="140"
            image={thumbnail}
            alt={props.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {props.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: props.description }}
              ></div>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

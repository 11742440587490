import { gql } from "@apollo/client";

export const GET_COMPONENT_TEXT_BY_COMPONENT_NAME = gql`query GET_COMPONENT_TEXT_BY_COMPONENT_NAME($filter: component_texts_filter) {
  component_texts(filter: $filter) {
    id
    component_name
    component_text
  }
}`;

export default GET_COMPONENT_TEXT_BY_COMPONENT_NAME;



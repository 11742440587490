import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import { SocialIcon } from 'react-social-icons';
import GET_CONTACT from "./graphql/Contact";
import { useQuery } from "@apollo/client";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const stylingObject = {
    socialIcon: {
      marginRight: "5px",
      marginTop: "5px"
    }
  }

export default function DoTogetherModal(props){
    const { loading, error, data } = useQuery(GET_CONTACT);
    if (loading) return null;
    if (error) return `Error! ${error}`;

    const {vk_nickname, whatsapp_phone, telegram_name, facebook_url} = data.contact;

    let telegramUrl = "https://telegram.me/" + telegram_name;
    let whatsappUrl = "https://wa.me/" + whatsapp_phone;
    let vkUrl = "https://vk.com/" + vk_nickname;

    return (
        <div>
            <Modal
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Задать вопросы, договориться о времени
            </Typography>
            <div>
              <SocialIcon style={stylingObject.socialIcon} url={telegramUrl} network="telegram" />
              <SocialIcon style={stylingObject.socialIcon} url={whatsappUrl} network="whatsapp" />
              <SocialIcon style={stylingObject.socialIcon} url={vkUrl} network="vk" />
              <SocialIcon style={stylingObject.socialIcon} url={facebook_url} network="facebook" />
            </div>
          </Box>
        </Modal>
        </div>
    )
}